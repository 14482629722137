<template>
  <button :disabled="isDisabled" :class="`btn s-button rounded-pill s-text-${colorFont} s-bg-${color}`">
    {{ label }}<b><i :class="`ps-1 bi bi-${icon}`" v-if="icon"></i></b>
  </button>
</template>

<script>
export default {
  props: {
    label: String,
    color: String,
    isDisabled: Boolean,
    colorFont: {
      type: String,
      default: "dark",
    },
    icon: String,
    size: {
      type: String,
      default: "",
    },
  },

}
</script>

<style>
.s-button.s-bg-primary:hover {
  background-color: var(--primary-lighten);
  color: var(--light);
}

.s-button.s-bg-secondary:hover {
  background-color: var(--secondary-lighten);
}

.s-button.s-bg-info:hover {
  background-color: var(--info-lighten);
}

.s-button.s-bg-success:hover {
  background-color: var(--success-lighten);
}

.s-button.s-bg-warning:hover {
  background-color: var(--warning-lighten);
}

.s-button.s-bg-danger:hover {
  background-color: var(--danger-lighten);
}

.s-button.s-bg-light:hover {
  background-color: var(--primary-lighten);
  color: var(--light);
}

.s-button.s-bg-bg-light:hover {
  background-color: rgb(243, 238, 255);
  color: var(--primary)
}

.s-button.s-bg-bg-light.danger:hover {
  background-color: var(--danger-lighten);
  color: var(--danger)
}

.s-button:disabled {
  background-color: gray;
  border: none;
  color: var(--light);
}
</style>
