<template>
    <div class="modal fade modal-lg" ref="modalPattern" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header text-white" style="background-color: #602a84;">
                    <span class="fs-5">Detalhamento do Vídeo</span>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="emitClose"></button>
                </div>
                <div class="modal-body text-dark">
                    <video 
                        v-if="open"
                        :width="width"
                        :height="height" 
                        controls
                    >
                        <source :src="url" type="video/mp4">
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
  
    export default {
        name: "modalPattern",
    
        props: {
            width: String,
            height: String,
            url: String,
            open: false,
        },
    
        methods: {
            emitClose() {
                this.$emit("close")
            }
        }
    }
</script>
  
<style></style>